import moment from 'moment'
import 'moment/locale/de'
import 'moment/locale/en-gb'
import {i18n} from 'next-i18next'

export const parseMultiLanguageDates = (dateString) => {
  const locales = ['de', 'en']
  let parsedStart = null
  let parsedEnd = null

  for (const locale of locales) {
    moment.locale(locale)
    // Case 1: "11. - 12. Oktober 2024" or "11. - 12. October 2024"
    if (dateString.match(/^\d{1,2}\.\s-\s\d{1,2}\.\s[A-Za-zäöüß]+\s\d{4}$/)) {
      const [startDay, endPart] = dateString
        .split(' - ')
        .map((part) => part.trim())
      const [endDay, month, year] = endPart.split(' ')

      const startDate = `${startDay} ${month} ${year}`
      const endDate = `${endDay} ${month} ${year}`

      parsedStart = moment(startDate, 'DD. MMMM YYYY', true)
      parsedEnd = moment(endDate, 'DD. MMMM YYYY', true)
      parsedEnd = parsedEnd.add(1, 'days')
    }

    // Case 2: "11. Oktober 2024 - 12. Oktober 2024" or "11. October 2024 - 12. October 2024"
    if (
      dateString.match(
        /^\d{1,2}\.\s[A-Za-zäöüß]+\s\d{4}\s-\s\d{1,2}\.\s[A-Za-zäöüß]+\s\d{4}$/
      )
    ) {
      const [startPart, endPart] = dateString
        .split(' - ')
        .map((part) => part.trim())

      parsedStart = moment(startPart, 'DD. MMMM YYYY', true)
      parsedEnd = moment(endPart, 'DD. MMMM YYYY', true)
      parsedEnd = parsedEnd.add(1, 'days')
    }

    // Case 3: "11. Oktober 2024" or "11. October 2024"
    if (dateString.match(/^\d{1,2}\.\s[A-Za-zäöüß]+\s\d{4}$/)) {
      parsedStart = moment(dateString, 'DD. MMMM YYYY', true)
      parsedEnd = parsedStart
    }

    // If parsing succeeded, break out of the loop
    if (parsedStart?.isValid() && parsedEnd?.isValid()) {
      break
    }
  }

  if (parsedStart?.isValid() && parsedEnd?.isValid()) {
    return {
      start: parsedStart.toDate(),
      end: parsedEnd.toDate()
    }
  }

  console.error('Invalid date format:', dateString)
  return null
}

export const extractDatesForCalendar = (data) => {
  const formattedEvents = new Map()

  data.products.nodes.forEach((product) => {
    product.variations.nodes.forEach((variation) => {
      variation.attributes.nodes.forEach((attribute) => {
        if (attribute.name === 'pa_dates') {
          const parsedDates = parseMultiLanguageDates(attribute.value)
          if (parsedDates) {
            if (moment(parsedDates.start).isSameOrAfter(moment(), 'month')) {
              const eventKey = `${product.name}-${parsedDates.start}-${parsedDates.end}`
              const isAvailable = variation.stockQuantity !== null

              if (
                !formattedEvents.has(eventKey) ||
                (isAvailable &&
                  formattedEvents.get(eventKey).stockQuantity === null)
              ) {
                formattedEvents.set(eventKey, {
                  title: `${product.name}${
                    isAvailable ? '' : ` - ${i18n.t('global:soldOut')}`
                  }`,
                  start: parsedDates.start,
                  end: parsedDates.end,
                  stockQuantity: variation.stockQuantity,
                  uri: product.uri
                })
              }
            }
          }
        }
      })
    })
  })

  return Array.from(formattedEvents.values())
}
